.gettingstarthead{
    background-color: #fff;
    border-radius: 10px !important;
    padding: 9px 18px 9px 19px;
    width: 90%;
   
    font-size: 14px;
    color: #939598;
    text-align: center;
    font-weight: 400;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
}
.gettingstartcontent{
    background-color: #fff;
    border-radius: 10px !important;
    padding: 9px 18px 9px 19px;
    width: 90%;
    font-size: 14px;
    color: #939598;
    text-align: center;
    font-weight: 500;
    margin-left: 35px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;  
}
.mainStartView{
    display: flex;
    flex-direction: column;
    background-color: #e1e1e1;
    height:100vh;
}
.gettingStartTitle{
    min-height: 49px;
    margin-left: 16px;
    background: #E1E1E1;
    float: left;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #45818E;
}
.contentHead{
    font-size: 14px;
    color: #939598;
    text-align: left;
    margin: 16px 0px;
    font-weight: 500;
}
.developmentContent{
    display: flex;
    flex-direction: row;
}
.developsubheadcontent{
    font-family: "Open Sans", sans-serif;
    text-align: left;
    font-size: 13px;
    margin-top: 20px;
    line-height: 1.4;
    margin-left: 11%;
    font-weight: 400;
}

.develophead{
   
    font-weight: 500;
}
.developheadcontent{
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 1.4;
    margin-left: 1%;
   text-align: left;
   font-weight: 400;
}
.developmentdiv{
    text-align: left;
}
.developmentregbtn{
    border-radius: 6px !important;
    border: 2px solid #45818E;
    font-weight: bold;
    padding: 3px 12px;
    background-color: #45818E;
    float: right;
    color: white;
}
.developmentregbtn:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.datafreeLayout{
    display: flex;
    flex-direction: column;
    margin-left: 39px;
    margin-top: 30px;

}
.datafreeLayoutRow{
    display: flex;
    
    flex-wrap: wrap;
}
.datafreewebtitle{
    color: #45818E;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}
.datafreeLayoutbtns{
    margin-right: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.Layoutbtn{
    background: #C9DAF7;
    color: #0a4e8d;
    font-weight: bold;
    margin-bottom: 30px;
    display: inline-block;
    
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 50px;
    font-size: 14px;
}
.Layoutbtn:hover{
    background: #7a94c1;
    transition: all 0.3s;
    color: #333;
}
.DialogContentText{
    text-align: center;
    padding-top: 21px;
}

@media (max-width: 1024px) {
    .mainStartView {
       margin-top: 110px;
    
    }
    .gettingStartTitle {
        min-height: 49px;
        margin-left: 16px;
        background: #E1E1E1;
        float: left;
        padding: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        color: #45818E;
    }
    .gettingstarthead {
      
        margin-left: 20px;
       
    }
    .gettingstartcontent{
       
     
        margin-left: 20px;
        
    }
  }
  
  @media (max-width: 480px) {
  }
  
  @media (max-width: 320px) {}
.mainBoxView {
  height: 100vh;
  background-color: #e1e1e1;
  width: 100%;
  z-index: 2;
  margin-top: 0;

}

.mainMobView {
  height: 100vh;
  background-color: #e1e1e1;
  width: 83%;
  position: fixed;
  margin-top: 0;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.NavBoxDiv {
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #fff;
}

.navBoxDiv {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 3%;
  background-color: #fff;
}

.btnRight {
  text-align: right;
}

.sidNavViewWidth {
  width: 285px;
}

.accountBoxDiv {
  display: flex;
  flex-direction: column;

  padding-right: 3%;
  background-color: #fff;
}

.spaceRight {
  margin-left: 19px;
}

.subHead {
  font-size: 16px;
  margin-top: 19px;
  margin-bottom: 8px;
  font-weight: 700 !important;
  color: #333333;
  font-family: "Open Sans", sans-serif;
}

.spaceTop {
  margin-top: 10px;
}

.formtable {
  width: 100%;
  margin-bottom: 20px;
}

.titlePopup {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 18px;
  padding: 15px;
}

.bottomDiv {
  border: solid #e5e5e5;
  border-width: thin;
  margin-bottom: 5px;
}

.NavBoxRow {
  display: flex;
  flex-direction: row;

  margin-top: 3%;
}

.MuiTable-root {
  width: 97% !important;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}

.NavBoxRowFilter {
  float: left;
  clear: both;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  min-height: .01%;
}

.RowBoxFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 4%; */

}

.col-gap {
  column-gap: 2rem;
}

.navBottom {
  margin-bottom: 30px;
}

.navBoxRowFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;

}

.pullright {
  float: right !important
}

.datafreeRowFilter {
  margin-left: 3%;
  margin-top: 2%;
  border-bottom: 1px solid #aaa5a5;
}

.searchVersion {
  text-align: right;
  margin-right: 1%;
  margin-top: 4%;
}

.searchVersionInput {
  width: 22% !important;

  outline: none !important;
  border: 1px solid black !important;
  outline: none !important;
  border: 1px solid black !important;
}

.searchbtn {
  outline: none !important;
  box-shadow: none !important;
  display: inline-block;

  font-weight: 400;
  text-align: center;

  cursor: pointer;
  border: 1px solid transparent;

  padding: 6px 12px;
  font-size: 14px;

  border-radius: 4px;
}

.NavBoxRowLogo {
  color: black;
  height: 20px;
}

.NavBoxRowText {
  font-size: 20px;
  color: #29b4b6;
  padding-left: 15px;
}

.displayVersion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headTitle {
  font-size: 18px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.top {
  margin-top: 48%;
}

.tops {
  margin-top: 53%;
}

.MuiDialog-paperWidthLg {
  max-width: 1098px !important;
}

.wrapper-title-company {
  padding: 0px 25px;
}

.wrapper-title {
  width: 100%;
  float: left;
  padding: 25px;
  background-color: #fff;

  min-height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  overflow-x: auto !important;
  min-height: 0.01% !important;
}

.applications-list-hr {
  border-top: 1px solid #9b9b9b;
}

/* .application-form-control {
  height: 34px;
  outline: none;
  margin-right: 12px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px;
  font-size: 14px;

  color: #555;
} */

.application-search-btn {
  box-shadow: none !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  outline: none;
  margin-right: 12px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;

  color: #555 !important;
}

.search-div-for-applicationList .application-search-btn {
  height: 32px;

  min-width: 99px;
  padding: 0px 15px;
}

.table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.btn-green {
  border-radius: 6px !important;
  border: 2px solid #93c47d !important;
  color: #839b78 !important;
  font-weight: bold !important;
  padding: 3px 12px !important;
}

.displayFlex {
  flex: 1;
}

.lineBottom {
  border: solid #e5e5e5;
  border-width: thin;
  margin-bottom: 25px;
}

.labelHead {
  text-align: center;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #333333;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 0px !important;
}

.search-div-for-applicationList {
  float: left;
  clear: both;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 100%;
}

.searchVersionInput {
  padding-left: 8px !important;
  width: 17%;
  padding-top: 4px !important;
  padding-bottom: 1px !important;
}

.fatimesclass {
  font-size: 12px;
  padding-top: 4px;
}

.form-picker {
  padding: 0px !important;
}

.NavBoxRowFilterLabel {
  font-size: 14px;
  font-weight: 300;
  height: 34px;
  color: #333333;
  font-family: "Open Sans", sans-serif;

}

.auto {

  width: auto;

}

.NavBoxRowFilter_Label {
  font-size: 14px;
  font-weight: 300;
  height: 48px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  padding-top: 8px;
  padding-right: 5px;
  margin-left: 15px;
}

.typeFilterLabel {
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 13px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  box-shadow: none !important;
  font-size: 14px !important;
}

.application-form-control {
  height: 34px;
  -webkit-appearance: listbox;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  margin-right: 18px;
  border-radius: 6px;
  box-shadow: none;

  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.errorClass {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #c00 !important;
  text-align: left !important;
  text-decoration: none !important;
}

.errorField {
  background-color: #edd !important;
  border: 1px solid #c00 !important;
}

.ButtonDiv {
  height: 60px;
}

.tableDiv {
  margin-top: 3%;
}

.gridBox {
  background: #f4f4f4;
  border-radius: 6px !important;
  border: 2px solid grey;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.gridBoxDisable {
  background: #f4f4f4;
  border-radius: 6px !important;
  border: 2px solid #e6e6e6;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.reviewApp {
  border-radius: 6px !important;
  border: 1px solid #1e90ff;
  color: #1e90ff;
  font-weight: bold;
  padding: 3px 5px;

  width: 100%;
  font-size: 12px;
}

.reviewAppDisable {
  border-radius: 6px !important;
  border: 1px solid #d2e9ff;
  color: #d2e9ff;
  font-weight: bold;
  padding: 3px 5px;
  background-color: white;

  width: 100%;
  font-size: 12px;
}

.approve-direct {
  width: 100%;
}

.decommisonApp {
  border-radius: 6px !important;
  border: 2px solid #ff0000;
  color: #ff0000;
  font-weight: bold;
  padding: 3px 5px;

  width: 100%;
  font-size: 12px;
  margin-top: 5px;
}

.decommisonAppDisable {
  border-radius: 6px !important;
  border: 2px solid #ffcccc;
  color: #ffcccc;
  font-weight: bold;
  padding: 3px 5px;
  background-color: white;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
}

.contenthead {
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 8px !important;
  line-height: 1.42857 !important;
  color: #333333 !important;
  font-family: "Open Sans", sans-serif !important;
}

.NavBoxRowFilterinput {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
}

/* .NavBoxRowFilterinput {
  width: 90%;
  margin-bottom: 9%;
  height: 36px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
} */

.NavBoxRowFilterChannel {
  width: 90%;
  margin-bottom: 9%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.NavBoxRowFilterMno {
  margin-bottom: 9%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.NavBoxRowFilterinputs {
  width: 90%;
  height: 34px;
  margin-bottom: 4%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}


.NavBoxRowFilterinputerr {
  width: 90%;
  margin-bottom: 1%;
  margin-top: 2%;
  padding: 6px 12px;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  border-color: #d62020;
  color: #c63d3d;
  background-color: #edd;
  border: 1px solid #c00;
}


.NavFilterinput {
  width: 70%;
  margin-bottom: 9%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.Navverifiedinput {
  width: 90%;
  margin-bottom: 9%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  height: 36px;
}

.NavBoxRowFilterinputBox {
  width: 76%;
  margin-bottom: 9%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  height: 23px;
}

.NavinputBox {
  width: 70%;
  margin-bottom: 9%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.applicationRowFilterinput {
  margin-bottom: 18%;
  width: 84%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.appRowFilterinput {
  margin-bottom: 18%;

  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
}

.applicationinput {
  margin-bottom: 18%;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  border-radius: 6px !important;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  width: 90%;
}

.divSpace {
  flex: 1;
}

.divwidth {
  width: 25px;
}

.createButtonDiv {
  text-align: right;
  margin-right: 3%;
}

.navRowButton {
  height: 31px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 28px;
  /* margin-top: 33px; */
  margin-right: 9px;
  margin-bottom: 1%;
}

.checkbox {
  display: inline-block;
  margin: 0px 19px;
  border: 2px solid grey;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  font: 900 2.6em/0.7 monospace;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  background: white;
  color: dimgrey;
}

.checkboxForm {
  display: inline-block;
  margin: 0px 19px;
  border: 2px solid grey;
  border-radius: 3px;
  width: 26px;
  height: 23px;
  font: 900 2.6em/0.7 monospace;

  vertical-align: middle;
  text-align: center;
  background: white;
  color: dimgrey;
}

.checkbox:checked:after {
  content: "\00D7" !important;
  display: inline-block;

  border: 2px solid grey;
  border-radius: 3px;
  width: 26px;
  height: 27px;

  vertical-align: middle;
  text-align: center;
  background: white;
  color: dimgrey;
}

.existCheck {
  width: 15px;
  height: 15px;
}

.binuvesionnote {
  font-size: 13px !important;
  font-family: "Open Sans", sans-serif !important;
}

.MuiTable-Cell-root {
  border: 1px solid #e7ecf1 !important;
}

.topALign {
  margin-top: 10px;
}

.defultbtn {
  height: 34px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px !important;
  margin-right: 9px;
  margin-bottom: 1%;
}

.searchBefore {
  height: 34px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 25px;
  margin-top: 35px;
}

.popupOk {
  height: 31px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 500;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px;
  width: 50%;
  align-content: center;
}

.popupbutton {
  display: flex;
  justify-content: center;
}

.modalStyle {
  width: 50%;
  height: 50%;
}

.diaContent {
  flex: none !important;
}

.diaContents {
  flex: none !important;
  padding: 21px 71px !important;
}

.DiaPop {
  min-height: 50px !important;
}

.searchAfter {
  height: 34px;
  border-radius: 6px !important;
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 25px;
  margin-top: 35px;
}

.defultbtnafterclick {
  height: 34px;
  border-radius: 6px !important;
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  margin-right: 9px;
  margin-bottom: 1%;
  padding: 0px 15px !important;
}

.btnmargin {
  margin-top: 20px;
}

.licbtn {
  height: 31px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 500;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px;
  margin-top: 38px;
  margin-right: 9px;
  margin-bottom: 1%;
}

.licbtnafterclick {
  height: 32px;
  border-radius: 6px !important;
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px;
  margin-top: 38px;
  margin-right: 9px;
  margin-bottom: 1%;
}

.pubSelect {
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  color: #555;
}

.tableMargin {
  margin: 0px 1px 0px 16px;
}

.NewCheck input[type="checkbox"]:before {
  border: 1px solid #333;
  content: "\00a0";
  background-color: white;
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  text-align: center;
}

.NewCheck input[type="checkbox"]:checked:before {
  background: white;
  color: black;
  content: "\2713";
  font-weight: 700;
  text-align: center;
}

.licbtnafterclick:hover,
.licbtn:hover,
.defultbtn:hover,
.defultbtnafterclick:hover,
.searchAfter:hover,
.searchBefore:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.navRowButtonafterclick {
  height: 32px;
  border-radius: 6px !important;
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 28px;
  /* margin-top: 33px; */
  margin-right: 9px;
  margin-bottom: 1%;
}


.deleteRow {
  border-radius: 6px !important;
  border: 2px solid #ff0000;
  color: #ff0000;
  font-weight: bold;
  padding: 3px 15px;

  width: 100%;
  font-size: 12px;
}

.deleteRows {
  border-radius: 6px !important;
  border: 2px solid #ff0000;
  color: #ff0000;
  font-weight: bold;
  padding: 8px 20px;
  background-color: #ffff;
  width: 100%;
  font-size: 14px;

}

.FlexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.tableFlexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.-container inpuinputt {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.cmptitleColor {
  color: rgba(0, 0, 0, 0.87) !important;
}

.navRowButton:hover {
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
}

.cmpdialogtitle {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 100 !important;
  font-size: 18px !important;
  padding: 15px 14px 12px 23px;
  color: black;
  color: inherit;
}

.binudefaultbtn {
  height: 31px;
  font-size: 14px;
  border-radius: 6px !important;
  background-color: #fff;
  border: 3px solid #93c47d;
  color: #93c47d;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px;
  margin-top: 20px;
  margin-right: 9px;
  margin-bottom: 1%;
}

.binudefaultbtnafterclick {
  height: 32px;
  border-radius: 6px !important;
  background-color: #cccccc85 !important;
  color: rgba(0, 0, 0, 0.589) !important;
  border: 3px solid rgba(0, 0, 0, 0.274) !important;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 15px;
  margin-right: 9px;
  margin-bottom: 1%;
}

.cmpdialoginputtext {
  margin-bottom: 5px;
  margin-top: 12px;
  /* display: inline-block; */
  font-weight: 300;
  font-size: 14px;
}

.ipinputtext {
  margin-bottom: 5px;
  margin-top: 12px;
  margin-left: 5px;
  /* display: inline-block; */
  font-weight: 300;
  font-size: 13px;
  color: #333;
}

.connectLabel {
  font-weight: normal !important;
  font-style: normal;
  color: #221f1fe0;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
  margin-left: 32px;
}

.InputflexRowScroll {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  background-color: rgb(235 233 233);
  border-radius: 6px;
  height: 90px;
  width: 94%;
  margin-left: 24px;
}

.InputflexRows {
  margin-bottom: 6px;
  border: 2px solid #c7c0c0;
  height: 300px;
  border-radius: 6px;
}

.connectText {
  width: 90%;
  margin-left: 42px;
  height: 50px;
  margin-top: 5px;
  background-color: white;
  outline: none;
  border: 1px solid #c2cad8;
}

.connectInput {
  width: 90%;
  margin-left: 42px;
  height: 30px;
  margin-top: 5px;
  background-color: white;
  outline: none;
  border: 1px solid #c2cad8;
}

.Pub_button {
  background-color: #4faa26;
  border-radius: 6px !important;
  border: 1px solid #000000;
  padding: 8px 12px;
  font-weight: bold;
  color: #ffffff;
  width: 40%;
  font-size: 15px;
  cursor: pointer;
  margin-top: 12px;
  height: 40px;
}

.Pub_button:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.inlineText {
  border: 1px solid #c2cad8;
  background-color: #eef1f5;
  opacity: 1;
  padding: 5px 17px;
  width: 30%;
  display: inline;
  outline: none;
  margin-left: 6px;
}

.Connectinputtext {
  margin-bottom: 5px;
  margin-top: 12px;
  /*margin-left: 5px;*/
  /* display: inline-block; */
  font-weight: 300;
  font-size: 14px;
  color: #333;
}

/*Connect*/

.workbenchs {
  margin-top: 10px;
  width: 95%;
  padding: 7px 9px 6px 5px;
  background-color: #fff;
  border-radius: 10px !important;
  white-space: normal;
  border: 3px solid #6c99e1;
  color: #6c99e1;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  height: 42px;
  font-size: 15px;
}

.workbenchsDisable {
  margin-top: 10px;
  width: 95%;
  padding: 7px 9px 6px 5px;
  background-color: #fff;
  border-radius: 10px !important;
  white-space: normal;
  color: #333;
  background-color: #e6e6e6;
  border: 3px solid #adadad;
  pointer-events: none;
  outline: none !important;
  box-shadow: none !important;
  height: 42px;
  font-size: 15px;
}

.workbenchs:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.inviteButtonCancel {
  border-radius: 6px !important;
  color: #ec0f2e;
  border: 3px solid #f32b47;
  font-size: 14px;
  height: 31px;
  font-weight: bold !important;
  padding: 2px 14px !important;
  cursor: pointer;
  margin-right: 9px;
  margin-bottom: 10px;
  background-color: #fff;
}

.inviteButtonCancel:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.BuildBtn {
  background-color: white;
  color: #93C47D;
  border: 3px solid #93C47D;
  height: 30px;
  border-radius: 6px;
  width: 120%;
  font-weight: 600;
}

.BuildBtn:hover {
  color: #333;
  font-weight: 600;
  background-color: #e6e6e6;
  border-color: #adadad;
  cursor: pointer;
}

.BuildBtn:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  cursor: pointer;
}

.boxConnect {
  width: 90%;
  margin-left: 18px;
  height: 120px;
  margin-bottom: 11px;
}

.inviteButtonPublish:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.ConfigLabel {
  font-weight: normal !important;
  font-style: normal;
  color: #221f1fe0;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

.inviteButtonPublish {
  border-radius: 6px !important;
  border: 3px solid #4360ec;
  color: #1f23e6;
  font-weight: bold;
  font-size: 14px;
  height: 31px;
  font-weight: bold !important;
  padding: 2px 14px !important;
  cursor: pointer;
  margin-right: 9px;
  margin-bottom: 10px;
  background-color: #fff;
}

.workbenchs:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.newconfiginputRows {
  height: 25px;
  margin-top: 10px;
  width: 160px;
  background-color: #c3c6c952;
  border: 3px solid #bec2c9;
}

.wrapInputs {
  width: 62%;
  height: 22px;
  background-color: #eef1f5;
  opacity: 1;
  cursor: not-allowed;
  border: 1px solid #c2cad8;
  margin-bottom: 14px;
}

.datafreeConnectButton {
  border-radius: 6px !important;
  border: 1px solid #c4c6ca !important;
  margin-top: 20px;
  background: #C9DAF7;
  color: #3D85C6;
  font-weight: bold;
  white-space: initial;
  margin-bottom: 15px;
  border-color: #e4e8ee;
  font-size: 14px;
  height: 40px;
  width: 45%;
  float: left;
  padding: 2px 14px !important;
  cursor: pointer;
}

.datafreeConnectButton:active {
  border: 1px solid #9e9191 !important;
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.datafreeConnectButton:hover {

  border: 1px solid #9e9191 !important;
  background: #7a94c1;
  color: #333;
  border-color: #9e9191;
}

#binuDeploymentIpsSearch .form-control {
  border-radius: 4px !important;
  height: 34px;
  padding: 6px 6px;
  font-size: 12px;
}

.datafreeConnectBtnDanger:hover {
  border: 1px solid #9e9191 !important;
  background: #7a94c1;
  color: #333;
  border-color: #9e9191;
}

.form-control:disabled,
.form-control[readonly] {
  height: 33px !important;
  background-color: #f4f4f4 !important;
  ;
  border-radius: 3px !important;
  font-size: 14px;
}

.formRight {
  margin-right: 3px;
}

.datafreeConnectDisable {
  border-radius: 6px !important;
  border: 1px solid #d7dfee !important;
  margin-top: 20px;
  background: #d6e2f5;
  color: #6fabe1;
  font-weight: bold;
  white-space: initial;
  margin-bottom: 15px;
  border-color: #d7dfee;
  font-size: 14px;
  height: 40px;
  width: 45%;
  float: left;
  padding: 2px 14px !important;
  cursor: pointer;
}

.datafreeConnectBtnDanger {
  border-radius: 6px !important;
  margin-top: 20px;
  font-weight: bold;
  white-space: initial;
  margin-bottom: 15px;
  font-size: 14px;
  height: 40px;
  width: 45%;
  float: left;
  padding: 2px 14px !important;
  cursor: pointer;
  border: 1px solid #c63d3d !important;
  border-color: #d62020;
  background: #f7a3a3;
  color: #c63d3d;
}

.datafreeConnectBtnDangerDisable {
  border-radius: 6px !important;
  margin-top: 20px;
  font-weight: bold;
  white-space: initial;
  margin-bottom: 15px;
  font-size: 14px;
  height: 40px;
  width: 45%;
  float: left;
  padding: 2px 14px !important;
  cursor: pointer;
  border: 1px solid #eb8585 !important;
  border-color: #e08484;
  background: #f7a3a3;
  color: #e47f7f;
}

.overflow {
  margin-bottom: 20px;
}

.selectIcon {
  width: 100%;
  border-radius: 5px !important;
  height: 38px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  margin-top: 10px;
}

.auth {

  font-size: 14px;
}


.directFieldSelecterr {
  height: 34px;
  width: 100%;
  padding-left: 7px;
  outline: none;
  border-radius: 3px !important;
  background-color: #edd;
  border: 1px solid #c00;
}

.modalClose:focus,
.modalClose:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;

}

.modalClose {
  color: #e5e5e5;
  text-decoration: none;
  cursor: pointer;
  float: right;
  font-size: 22px;

}

.bodyMargin {
  width: 100%;
  margin-left: 285px;
}

.bodyMarginOpen {
  width: 100%;
  margin-left: 58px;
}

.adminportal {
  display: flex;
}

@media (max-width: 1024px) {

  .mainBoxView {

    margin-top: 110px;

  }

  .mainMobView {
    width: 100%;
    margin-top: 110px;
    overflow-x: scroll;
    overflow-y: scroll;

  }

  .table-responsive {
    width: 100%;
    white-space: nowrap;
    margin-bottom: 15px;
    padding: 15px 0px 29px 0px;
    overflow-y: hidden;
    border: 1px solid #e7ecf1;
    overflow-x: scroll !important;
    min-height: .01% !important;
  }

  .tabViewMenu {
    margin-top: 40px
  }

  .bodyMargin {
    margin-left: 0px
  }

  .sidebar {
    height: auto;
    position: fixed;
    width: 100%;
    color: rgba(165, 165, 165, 1);
    overflow-y: hidden;
    overflow-x: scroll;
    top: 0;
    z-index: 1;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: 110px;
  }

  .bodyMarginOpen {
    margin-left: 0px;
  }

  .licbtn {
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .mainBox {
    height: 100vh;
    background-color: #e1e1e1;
    width: 100%;
    /* position: fixed; */
    margin-top: 110px;
    z-index: 1;
  }

  .bodyMargin {
    margin-left: 0px
  }

  .sidebar {
    height: 570px;
    position: fixed;
    width: 100%;
    color: rgba(165, 165, 165, 1);
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    z-index: 1;
    flex-direction: column;
  }

  .table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }

  .cmpdialogtitle {

    font-size: 17px !important;

  }
}

@media (max-width: 320px) {}
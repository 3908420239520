.applicationStartView {
  display: flex;
  flex-direction: column;
  background-color: #e1e1e1;
  min-height: 637px;
}

.applicationTitle {
  min-height: 70px;
  margin-left: 18px;
  background: #e1e1e1;
  float: left;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #45818e;
}

.applicationhead {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 9px 18px 5px 19px;
  width: 90%;
  /* height: 23px; */
  font-size: 14px;
  color: #939598;
  text-align: center;
  font-weight: 400;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.directapplication {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 9px 18px 5px 19px;
  width: 93%;
  margin-top: 2%;
  /* height: 23px; */
  font-size: 14px;
  color: #939598;
  text-align: center;
  font-weight: 400;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogdiv {
  margin-left: 3%;
  margin-right: 3%;
  text-align: left;
}

.applicationheadLogo {
  display: inline-block;
  padding-left: 0;
  margin-top: 1%;
  padding-right: 0;
  width: 11%;
  float: left;
}

.applicationHeadTitle {
  font-size: 20px;
  color: #29b4b6;
  min-height: 70px;
  margin-left: 18px;
  background: #e1e1e1;
  float: left;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.applicationurl {
  font-size: 24px;
  color: #221f1f;
  margin-top: 5px;
  margin-bottom: 10px;
  /* margin-left: 12px; */
  text-align: left;
  font-weight: 300;
}

.imgapplicationLogo {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  border-radius: 5px !important;
}

.applicationsubContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
}

.developTabContent {
  display: flex;
  flex-direction: column;
  border: 2px solid #000000;
  height: 512px;
  overflow-x: auto;
  width: 40%;
  border-radius: 10px !important;
}

.productType {
  display: flex;
  flex-direction: row;
  /* margin-left: 12px; */
  margin-top: 5px;
  padding-bottom: 15px;
}

.updateddate {
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
  color: #939598;
  text-align: left;
  margin-top: 5px;
  font-size: 15px;
  /* margin-left: 12px; */
}

.productTypeContent {
  font-weight: 600;
  color: #a89f9b;
  text-align: left;
  font-size: 15px;
}

.producttypeField {
  background-color: #ffffff;
  border: 1px solid #a89f9b;
  border-radius: 7px !important;
  padding: 2px 22px;
  outline: none;
  color: #a89f9b;
  margin-left: 12px;
  font-size: 14px;
  height: 20px;
  min-width: 15px;
}

.subbutton1 {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px !important;
  border: 3px solid #93c47d;
  background-color: white;
  color: #93c47d;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
  margin-left: 3px;
}

.subbutton4 {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px !important;
  color: #333;
  background-color: #e6e6e6;
  border: 3px solid #adadad;
  pointer-events: none;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
  margin-left: 3px;
}

.subbutton2 {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 3px;
  text-align: center;
  border-radius: 4px !important;
  padding: 4px 13px 3px 12px;
  color: #fff;
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px;
  background-color: #93c47d !important;
  cursor: pointer;
  border: 1px solid #fff;
}

.devsubbutton2 {

  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-top: 5px;
  text-align: center;
  border-radius: 4px !important;
  padding: 5px;
  color: #fff;
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px;
  background-color: #ffd966 !important;
  cursor: pointer;
  border: 1px solid #fff;
}

.delsubbutton2 {
  background-color: white;
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-top: 5px;
  text-align: center;
  border-radius: 6px !important;
  padding: 5px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px;
  border: 3px solid #ff0000 !important;
  color: #ff0000 !important;
  cursor: pointer;
}

.subbutton3 {
  width: 100%;
  outline: none !important;
  cursor: pointer;
  margin-left: 12px;
  margin-bottom: 5px;
  box-shadow: none !important;
  margin-top: 17px;
  font-size: 12px;
  white-space: normal;
  border: 1px solid #ff0000;
  color: #ff0000;
  font-weight: bold;
  padding: 3px 12px;
  border-radius: 6px !important;
}

.configDefinebtn {
  width: 100%;
  margin-top: 20px;
  background: #c9daf7;
  color: #333;
  font-weight: bold;
  white-space: initial;
  margin-bottom: 15px;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;

  cursor: pointer;
  border: 1px solid transparent;

  padding: 6px 12px;
  font-size: 13px;

  border-radius: 4px;
}

.configDefinebtn:hover {
  background: #3D85C6;
  ;

}

.applicationTab {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 9px 18px 5px 19px;
  width: 90%;

  margin-top: 30px;
  font-size: 14px;
  color: #939598;
  /* text-align: center; */
  font-weight: 400;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.developmentTab {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.developmentSecurityTab {
  text-align: right;
  margin-bottom: 3%;
}

.headline {
  text-align: center;
  color: #888787;
  font-weight: bold;
  font-size: 14px;
  margin-top: 30px;
}

.subheadline {
  font-size: 12px;
  color: #989898;
  font-weight: 700;
  margin-bottom: 6px;
  margin-left: 4%;
  text-align: left;
  margin-right: 4%;
  margin-top: 5%;
}

.editbtnNot {
  border-radius: 6px !important;
  border: 2px solid #ccc;
  cursor: pointer;
  margin-left: 7px;
  font-weight: bold;
  padding: 4px 12px;
  outline: none !important;
  box-shadow: none !important;
}


.editbtn {
  border-radius: 6px !important;
  border: 3px solid #93C47D;
  color: #93C47D;
  background-color: white;
  cursor: pointer;
  margin-left: 7px;

  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}

.editbtn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.editbtn:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.NotEdit {
  color: #333;
  background-color: #e6e6e6;
  pointer-events: none;
  border-radius: 6px !important;
  border: 3px solid #adadad;
  cursor: pointer;
  margin-left: 7px;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}

.NotEdit:hover {
  color: #333;
  background-color: #b6b4b4;
  border-color: #adadad;
  cursor: pointer;
}

.developTabContenthead {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
  margin-left: 10px;
  margin-top: 10px;
}

.developToolsTabContenthead {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.developTabContenthead span {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.developMaxTabContenthead {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;

  text-align: left;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.workbench {
  margin-top: 10px;
  width: 95%;
  padding: 7px 9px 6px 5px;
  background-color: #fff;
  border-radius: 10px !important;
  white-space: normal;
  border: 1px solid #6c99e1;
  color: #6c99e1;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
}

.workbench:hover {
  background-color: #ccc;
}

.defenition {
  margin-top: 10px;
  width: 95%;

  cursor: pointer;
  border-radius: 10px !important;
  white-space: normal;
  border: 1px solid #ff0000;
  color: #ff0000;
  padding: 7px 9px 6px 5px;
}

.defenition:hover {
  background-color: #ccc;
}

.developMaxTabContent {
  display: flex;
  flex-direction: column;
  border: 2px solid #000000;
  height: 512px;
  overflow-y: auto;
  width: 84%;
  margin-left: 35px;
  border-radius: 10px !important;
}

.colorinput {
  height: 12px;
  width: 54px;
  margin: 0;
  padding: 4px;
  color: #000000;
  outline: none;
}

.colorinputConnect {
  height: 17px;
  width: 66px;
  margin: 0;
  padding: 0;
  color: #000000;
  outline: none;
  padding-top: 4px;
  border-radius: 4px;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 17px;
  height: 17px;
  margin: 0;
  outline: none;
  margin-top: 4%;
  padding: 0;
}

.developSecurityTab {
  display: flex;
  flex-direction: row;
  text-align: right;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.tabInputfield {
  color: #333;
  background-color: #e6e6e6;
  border: 1px solid #c2cad8;
  outline: none !important;
  box-shadow: none !important;
  width: 36%;
  height: 24px;
  margin-left: 11px;
  padding: 6px 12px;
  font-size: 14px;
  /* line-height: 1.42857; */
  color: #555;
  display: block;
}

.entryPointinputField {
  height: 33px;
  width: 70px;
  width: -1%;
  margin-right: 5%;
  margin-left: 3%;
  margin-bottom: 2%;
  outline: none;
  padding: 1px 9px 1px 8px;
  background-color: #f4f4f4;
  border-radius: 3px !important;
  border: 1px solid #c2cad8;
}

.editBackground {
  background-color: white !important;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
}

.prodTabContent {
  overflow-y: scroll;
}

.entrypointLabel {
  font-weight: normal !important;
  font-style: normal;
  color: #221f1fe0;
  font-size: 13px;
  margin-bottom: 5px;
  padding-top: 1%;
  text-align: left;
}

.xmlvalidateStyle {
  color: #000000d9;
  padding: 1px 13px;
  border: 1px solid #c2cad8;
  margin-left: 10px;
  height: 30px;
}

.probtn {
  width: 100% !important;
  margin-top: 20px !important;
  background: #c9daf7 !important;
  color: #3d85c6 !important;
  font-weight: bold !important;
  white-space: initial !important;
  margin-bottom: 17% !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 7px;

  cursor: pointer;
  border-color: #ccc;
}

.deletedialoguettitle {
  text-align: center;
  font-weight: 700;
}

.deletecancelbtn {
  border: 2px solid #ff0000;
  color: #ff0000;
  margin-bottom: 2px;
  width: 100%;
  border-radius: 6px !important;
  font-weight: bold;
  padding: 3px 12px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #fff;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.dialoguRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.devHead {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.devmainHead {
  color: #888787;
  font-weight: bold;
  font-size: 14px;
}

.devconfigHead {
  color: #989898;
  font-weight: 400;
  font-size: 14px;
}

.imgdefaultapp {
  margin-left: 35px;
  /* margin-top: 45px; */
  width: 290px;
  height: 416px;
}

.MuiDialog-paperWidthMd {
  max-width: 840px !important;
}

.appnodeMainDiv {
  border: 2px solid #c1cad8;
  border-radius: 7px !important;
  padding: 7px;
}

.iviteRow {
  display: flex;
  flex-direction: row;
  text-align: right;
}

.xmlEditDiv {
  border: 2px solid #c1cad8;
  border-radius: 7px !important;
  padding: 7px;
  margin-bottom: 15px;
  outline: none;
}

.marginBottomClass {
  text-align: left;
}

.btnStyle {
  border: 3px solid #93C47D !important;
  color: #93C47D !important;
  background-color: white !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.filecustomSelect {
  color: #333;
  background: #eef2f6;
  border-color: #c0cad8;
  width: 25%;
  padding: 2px;
  border-radius: 3px !important;
  outline: none;
  margin-bottom: 16px;
  margin-left: 8px;
}

.filecustomsSelect {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  width: 25%;
  border-radius: 3px !important;
  outline: none;
  margin-bottom: 16px;
  margin-left: 8px;

}

.downarrow {
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #c0cad8;
  padding: 2px 3px 2px 3px;
  border-radius: 3px !important;
  color: #898a8c;
  margin-left: -22px;
  width: 18px;
  text-align: center;
  background: #eef3f7;
  pointer-events: none;

}

.formgroup {
  margin: 0px 3.10764px 0px 0px;
  width: 434px;
  height: 162px;
  outline: none;
}

a.textNEdit {
  color: #333;
}

a.textEdit {
  color: #93C47D;
}

.MuiInput-underline {
  content: "" !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.MuiInput-underline:before {
  content: "" !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: static !important;
}

.MuiInput-underline:after {
  content: "" !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: static !important;
}

.MuiFormControl-root {
  width: 50% !important;
  border: 1px solid #ccc !important;
  /*border-bottom:none !important;*/
  height: 36px;
}

.MuiInputBase-input {
  font-size: 14px !important;
  /*border-bottom: 1px solid #c2cad8 !important;*/
}

@media (max-width: 1024px) {
  .directapplication {

    margin-top: 14%;

    margin-left: 0px;

  }

  .applicationStartView {

    margin-top: 95px;
  }

  .applicationhead {

    flex-direction: column;

  }

  .dialoguRow {

    flex-direction: column;

  }

  .devmainHead {

    margin-bottom: 15px;
  }

  .devHead {
    margin-bottom: 15px;
  }

  .developmentTab {

    flex-direction: column;

  }

  .developTabContent {

    width: 100%;

  }

  .developMaxTabContent {

    width: 100%;
    margin-top: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    margin-left: 0px;
  }
}

@media (max-width: 480px) {}

@media (max-width: 320px) {}
.webCardMain {
  background-color: #fff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-right: 47px;
  width: 43%;
  max-width: none;
  padding: 2px;
  /* text-align: center; */
  font-weight: bold;
  font-size: 15px;
  color: #939598;
  padding:10px;
}
.webcardRowContent {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-left: 6px;
  margin-right: 29px;
}
.webcardcontent {
  text-align: left;
  margin-left: 20px;
  font-weight: 400;
}
.webcardtitle{
  padding-left: 35%;
}
.webcardbtn {
  padding: 9px 20px 9px;
  float: right;
  margin-right: 3px;
  border: 2px solid #45818e;
  background: #45818e;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px !important;
}
.webcardbtn:hover{
  background: #fff;
  color: #45818E;
}
.webCardLogo{
  height: 67px;
  margin-top: -23px;
}

@media (max-width: 1024px) {

  .webCardMain {

   width : 100%;

  }  
}
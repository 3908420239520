.mainRegView {
  display: flex;
  flex-direction: column;
  background-color: #e1e1e1;
  min-height: 634px;
}
.registerStartTitle {
    min-height: 50px;
    margin-left: 36px;
    background: #e1e1e1;
    float: left;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #45818e;
}
.optiontext {
  display: inline-block;

  color: #555;
  font-size: 13px;
}

.registerstarthead {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 12px 18px 12px 19px;
  width: 84%;
  /* height: 23px; */
  font-size: 13px;
  color: #939598;
  text-align: center;
  font-weight: 400;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}
.publishSteps {
  display: flex;
    flex-direction: row;
    margin-top: 11px;
    margin-bottom: 12px;
}
.publishStepsMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px !important;
  padding: 13px 18px 3px 18px;
  width: 84%;
  margin-left: 50px;
  margin-top: 18px;
}
.publishStepsMainTitle {
  color: #939598;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.imgBox {
  height: 80px;
}
.publishStepsbBox {
  border: 1px solid black;
  border-radius: 6px !important;
  width: 42%;
  padding: 4px 12px 13px 13px;
  
  font-size: 12px;

  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  height:auto;
}
.publishStepBoxContent {
  display: inline-block;
  color: #939598;
  line-height: 15px;
  font-size: 12px;
}
.publishStepBoxTitle {
  color: #939598;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0px 10px 0px;
}
.publishSubStepsbBox {
  border: 1px solid black;
    border-radius: 6px !important;
    width: 30%;
    padding: 5px 16px 39px 15px;
    font-size: 12px;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 63px;
}
.registerFormTitle {
  text-align: left;
  padding-bottom: 15px;
  padding-right: 7px;
  color: #939598;
  font-weight: 500;
  font-size: 15px;
}
.errorMax{
  margin-bottom: 12px;
  margin-top: 0px;
}
.inputDisabled{
  background-color: #f4f4f4 !important;
  border-radius: 3px !important;
}
.textcls{
  color:#333333 !important;
  font-family: "Open Sans", sans-serif !important;
  
  padding-top:3px;
    font-size: 15px !important;
    display: inline-block !important;
}
.textBoldHead{
    font-weight: bold !important;
    margin: 10px 0px !important;
    color: #333333;
}
.registerFormTitleMax {
  text-align: left;
  padding-bottom: 5px;
  color: #939598;
  font-weight: 500;
  font-size: 14px;
}
.registerFormTitleSwitch
{
  text-align: left;
  padding-bottom: 0px;
  color: #939598;
  font-weight: 300;
  font-size: 15px;
  margin-top: 14px;
}
.spanFormTitle {
  text-align: left;
  padding-bottom: 10px;
  padding-top: 1px;
  padding-left:8px;
  
  font-weight: normal;
}
.spancheck {
  margin-top: 4px;

}
.spanchecks {
  margin-top: 4px;
  cursor: not-allowed;
}
.registerInput {
  height: 33px;
  width: 100%;
  color: #555;
  font-size: 13px;
  padding: 0px 12px;

  border-radius: 3px !important;
  border: 1px solid #c2cad8;
  outline: none;
}


.registerInputs {
  height: 33px;
  width: 92%;
  color: #555;
  font-size: 13px;
  padding: 0px 12px;
  margin-left: 10px;
  margin-bottom: 5px;
  border-radius: 3px !important;
  border: 1px solid #c2cad8;
  outline: none;
}
.regFormInput {
  height: 33px;
  width: 232%;
  color: #555;
  font-size: 12px;
  padding: 0px 12px;
  border-radius: 3px !important;
  border: 1px solid #c2cad8;
  outline: none;
}
.regFormInputerr {
  height: 33px;
  width: 232%;
  color: #555;
  font-size: 12px;
  padding: 0px 12px;
  border-radius: 3px !important;
  background-color: #edd;
  border: 1px solid #c00;
  outline: none;
}
.registerInputerr {
  height: 33px;
  width: 100%;
  color: #555;
  font-size: 13px;
  padding: 0px 12px;
  border-radius: 3px !important;
  background-color: #edd;
  border: 1px solid #c00;
  outline: none;
}
.registersubInput {
  height: 33px;
  width: 90%;
  color: #555;
  font-size: 10px;
  padding: 0px 12px;
  border-radius: 3px !important;
  border: 1px solid #c2cad8;
  margin-top: 5px;
  outline: none !important;
}
.registersubInputerr {
  height: 33px;
  width: 90%;
  color: #555;
  font-size: 10px;
  padding: 0px 12px;
  border-radius: 3px !important;
  background-color: #edd;
  border: 1px solid #c00;
  margin-top: 5px;
  outline: none !important;
}
.registerIconUpload {
  display: flex;
  flex-direction: row;
  margin-top: 27px;
  margin-bottom: 10px;
  

}
.rowClass {
	display: flex;
	flex-direction: row;
}

.loadimg{
	width: 110px;
    height: 110px;
    background-color:white;
    border: 2px;
    border-radius: 7px !important;
    float: left;
}
.loadimg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px !important;
}
.registerIconBox {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.iconBox {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.registerInBox {
  display: flex;
  flex-direction: column;
}
.imgdefaultIcon {
  height: 100px;
  margin-left: 22%;
}

.iconimgupload {
  height: 100px;
  width:90px;
}
.IconText1 {
  font-size: 14px;
  color: #939598;
  font-weight: bold;
}
.fileIconText1 {
  font-size: 20px;
  color: #939598;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
}
.IconText2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #939598;
}
.IconText3 {
  font-size: 12px;
  color: #939598;
  padding-bottom: 20px;
  display: inline-block;
}
.errormsg {
  color: #c00 !important;
  margin-top: 5px;
  font-size: 11px;
}

.registerInputTextarea {
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  font-size: 10px;

  color: #555;
  outline: none;
  width: 342px;
  height: 40px;
  outline: none;
}
.registerInputTextareaerr {
  padding: 6px 12px;
 
  background-color: rgb(233, 199, 199);
  border: 1px solid #c00;
  font-size: 10px;

  color: #555;
  outline: none;
  width: 342px;
  height: 40px;
  outline: none;
}
.file-upload .file-select {
  display: block;
  border: 2px solid #c8c9ce;
  color: #93c47d;
  cursor: pointer;
  height: 29px;
  width: 70%;

  text-align: left;
  background: #ffffff;

  position: relative;
  border-radius: 5px !important;
}
.file-upload .appfile-select {
  display: block;
  border: 2px solid #c8c9ce;
  color: #93c47d;
  cursor: pointer;
  height: 26px;
  width: 100%;

  text-align: left;
  background: #ffffff;

  position: relative;
  border-radius: 5px !important;
}
.file-upload .file-select .file-select-button {
  padding: 0 3px;
  display: inline-block;
  line-height: 26px;
  vertical-align: top;
  font-size: 13px;
  font-weight: 700;
  border: 2px solid #93c47d;
  border-radius: 3px !important;
  margin: -2px 0 0px -2px;
}
.file-upload .appfile-select .file-select-button {
  padding: 0 3px;
  display: inline-block;
  line-height: 26px;
  vertical-align: top;
  font-size: 13px;
  font-weight: 700;
  border: 2px solid #93c47d;
  border-radius: 3px !important;
  margin: -2px 0 0px -2px;
}
.file-upload .file-select .file-select-name {
  line-height: 26px;
  display: inline-block;
  padding-left: 10px;
  vertical-align: top;
  word-break: break-word;
  white-space: nowrap;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #afa0bb;
}
.file-upload .appfile-select .file-select-name {
  line-height: 26px;
  display: inline-block;
  padding-left: 10px;
  vertical-align: top;
  word-break: break-word;
  white-space: nowrap;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #afa0bb;
}
.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.file-upload .appfile-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.regLayoutbtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.regInLayoutbtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 30px;
}
.appIdSwitch{
  width: 80%;
  /* float: left; */
  border: 2px solid #ccd4d2;
  border-radius: 7px !important;
  color: #939598;
  font-size: 13px;
  padding: 1px;
  overflow: hidden;
  height: 25px;
  text-align: center;
  margin-left: 5px;
  line-height: 22px;
  background-color: white;
}

.keySwitch{
  width: 250%;
  /* float: left; */
  border: 2px solid #ccd4d2;
  border-radius: 7px !important;
  color: #939598;
  font-size: 13px;
  padding: 1px;
  overflow: hidden;
  height: 25px;
  text-align: center;
  margin-left: 5px;
  line-height: 22px;
  background-color: white;
}
.fntSmall{
  font-size: 14px;
}
a {
  text-shadow: none;
  color: #337ab7;
}
.btnBtm{
  margin-bottom: 30px;
}
.Btm {
  margin-bottom: 30px;
  justify-content: normal;
}
.btnLeft{
  margin-left: 150px;
}
.appImage{
  height: 20px;
  margin-left: 76px;
}
.arrowImage{
  height: 20px;
  margin-left: 7px;
}
.maxRegisterForm{
  text-align: left;
  padding-bottom: 5px;
  color: #939598;
  font-weight: 300;
  font-size: 15px;
}
.topMax{
  margin-left: 154px;
  margin-top: -35px;
}
.leftMax{
  margin-left: 10px;
  margin-top: -30px;
}
.labelMax{
  text-align: left;
  padding-bottom: 10px;
  padding-right: 10px;
  color: #939598;
  font-weight: 300;
  font-size: 12px;
}
.labelsMax{
  text-align: left;
  padding-bottom: 10px;
  color: #939598;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 20px;
}
.registerMax {
  height: 29px;
  width: 65%;
  margin-bottom: 19px;
  color: #555;
  font-size: 13px;
  padding: 0px 9px;
  border-radius: 3px !important;
  border: 1px solid #c2cad8;
  outline: none;
}

.registerMaxerr {
  height: 29px;
  width: 65%;
  color: #555;
  font-size: 13px;
  padding: 0px 9px;
  border-radius: 3px !important;
  background-color: #edd;
  border: 1px solid #c00;
  outline: none;
}
.labelsArrow{
  text-align: left;
  padding-bottom: 10px;
  color: #939598;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 20px;
  
}
.labelMaxs{
  text-align: left;
  padding-bottom: 10px;
  color: #939598;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 20px;
}
.btnsLayout {
  display: flex;
  flex-direction: row;
}
.regLayoutbtn {
  background: #c9daf7;
  color: #3d85c6;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block;
  margin-right: 5px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 74px;
  font-size: 14px;
}
.regWebsiteLayoutbtn {
  background: #ccc;
  color: rgb(22, 18, 18);
  font-weight: bold;
  margin-bottom: 30px;
  display: inline-block;
  margin-right: 5px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border: 1px solid #5555555e;
  white-space: nowrap;
  padding: 6px 50px;
  font-size: 14px;
}
.regWebsiteLayoutbtn:hover {
  background: #7a94c1;
}
.regWebsiteLayoutbtn:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.regLayoutbtn:hover {
  background: #7a94c1;
}

.regLayoutbtn:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
@media (max-width: 1024px) {
  .mainRegView {
    margin-top:97px
}
.registerstarthead {
  margin-left: 34px;
  
}
.publishStepsMain {
  margin-left: 34px;
 
}
.publishSteps{
  flex-direction: column;
}
.publishStepsbBox {
  margin-top: 10px;
  width: 100%;
  padding: 4px 12px 13px 13px;
 
  margin-left: 0px;
  height: auto;
}
.publishSubStepsbBox {
  margin-top: 10px;
  width: 100%;
  padding: 5px 16px 39px 15px;
  height: auto;
  margin-left: 0px;
}
.regLayoutbtn {
  
  padding: 6px 30px;
  
}
}
.cardView {
  width: 46%;
 
  background-color: #fff;
  border-radius: 10px !important;
  margin-right: 32px;
  display: inline-block;
  margin-bottom: 30px;
}
.cardRow {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  margin-left: 42px !important;
  margin-top: 30px;
}
.pageView {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

}
.cardCollapseRow {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  margin-left: 180px;
  margin-top: 30px;
}
.cardtitle {
  width: 76%;
  display: -webkit-box;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 11px 4%;
  border-bottom: 1px solid #f4f4f4;
}
.cards-img {
  width: 69px;
  height: 69px;
  padding-right: 6px;
  /* background-color: #ddd; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px !important;
}

.edit-applicationlink {
  font-size: 20px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  /* width: 100%; */
  color: #939598;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 65%;
  word-break: break-word;
  float: left;
}
.deploymentstatus {
  background: #ffd966;
  width: 85px;
  /* margin-left: 80px; */
  float: right;
  text-align: center;
  border-radius: 4px !important;
  padding: 4px 13px 3px 12px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
}
.productionstatus {
  background: #93c47d;
  width: 85px;
  /* margin-left: 80px; */
  float: right;
  text-align: center;
  border-radius: 4px !important;
  padding: 4px 13px 3px 12px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
}
.lastseen {
  padding-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  width: 100%;
  color: #939598;
  font-size: 11px;
}
.livestatus {
  color: black;
  font-family: "Open Sans", sans-serif;
  float: right;
  display: inline-block;
  margin-right: 2%;
}
.poducttype {
  padding-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  color: #939598;
  font-size: 11px;
}
.binuappid {
  float: right;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  margin-right: 2%;
}
.application-dummydiv {
  min-height: 34px;
  display: inline-block;
  width: 100%;
}
.card-middle {
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 0px 5px;
  border-bottom: 1px solid #f4f4f4;
}
.cd1 {
  width: 50%;
  float: left;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 11px;
}
.rate {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  float: left;
  font-size: 11px;
  color: #939598;
}
.green-values {
  width: 100%;
  float: left;
  color: #a6ce39;
}
.card-middle span {
  font-size: 18px;
}
.cardfooter {
  width: 90%;
  justify-content: center;
  padding: 7px 0px;
  margin: 0 auto;
  margin-left: 9%;
}
.btndefault:hover,.btndefault:active{
  background-color: #d4d4d4;
}
.btndefault {
  width: 91%;
  border: 2px solid #a6ce39;
  color: #a6ce39;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px !important;
  display: inline-block;
  margin-bottom: 0;
  background-color: white;
  text-align: center;
  vertical-align: middle;

  cursor: pointer;

  padding: 9px 12px;
}
.headIcon {
  color: black;
  height: 20px;
}
.headToptext {
  font-size: 20px;
  color: #29b4b6;
  font-weight: lighter;
  padding-left: 15px;
  font-family: "Open Sans", sans-serif;
}
.textTop {
  display: flex;
  flex-direction: row;
}
.headdtextTop {
  display: flex;
  flex-direction: row;
}
.filtertextTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  margin-top: 20px;
}
.lifecyclefield {
  height: 25px;
  outline: none;
  padding: 0px 12px;
  background-color: #fff;
  border: 1px solid #c2cad8;
  color: #555;
  border-radius: 6px !important;
}
.imagelogo {
  display: block;
  height: 60px;
  width: 140px;
  object-fit: contain;
}
.loaderDiv {
  margin-top: 30px;
}
.TopNavCollapased {
  background-color: #fff;
  
  -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.datafree {
  display: flex;
  flex-direction: row;
  background-color: #e1e1e1;
  height: 100vh;
}
.labelhead {
  padding-bottom: 3%;
  font-size: 15px;
  margin-top: 2px;
  margin-right: 5px;
}
.datalabel {
  font-size: 15px;
  margin-right: 5px;
  margin-top: 2px;
}
@media only screen and (max-width: 949px) {
  .TopNavCollapased {
    margin-top: 14%;
   
  }
  .textTop {
    display: flex;
    flex-direction: column;
  }
  .filtertextTop {
    flex-direction: column;
  }
  .headdtextTop {
    padding-top: 12%;
  }
  .labelhead {
    padding-bottom: 3%;
  }
  .cardRow {
    margin-left: 23px !important;
  }
  .cardView {
    width: 100%;
   
    margin-right: 24px;
  }
  .cardtitle {
    width: 64%;
  }
}

.reportingView{
    display: flex;
  flex-direction: row;
  background-color: #e1e1e1;
  height: 100vh;
}
.reportpageView{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}
.reportNav{
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
      0 3px 1px -2px rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
      0 3px 1px -2px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    padding: 34px;
}
.reportingtextview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
}
.reportingtextTop{
    display: flex;
    flex-direction: row;
}
.reportheadIcon{
    color: black;
  height: 20px;
}
.reportheadToptext{
    font-size: 20px;
    color: #29b4b6;
    padding-left: 15px;
}
.reportsidetext{
    border-radius: 6px !important;
  border: 2px solid #93c47d;
  color: #93c47d;
  font-weight: bold;
  padding: 3px 12px;
  outline: none !important;
  box-shadow: none !important;
  display: inline-block;
  margin-bottom: 0;

  text-align: center;
  vertical-align: middle;

  cursor: pointer;

  white-space: nowrap;

  font-size: 14px;
}
.reportCard{
  background-color: #fff;
  border-radius: 10px !important;
  margin-bottom: 23px;
  color: #555;
  font-size: 15px;
  padding: 15px;
 
}
.reportcardView {
    background-color: #fff;
    border-radius: 10px !important;
   /* justify-content: space-between;
    padding: 15px; */
  }
  .labelfield {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;
  }
 
 .rrlabel{
 font-weight: normal;
 margin-bottom: 5px;
 color: #333333;
 font-size: 14px;
 display: inline-block;
 }
 .table thead tr th {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
  text-align: left !important;
  border: 1px solid #e7ecf1 !important;
}
  .applicationtext {
    background-color: #eef1f5;
    opacity: 1;
    padding: 0px 0px 0px 10px;
    height: 30px;
  outline: none;

    border: 1px solid #c2cad8;
    border-radius: 6px !important;
  }
  .runbutton{
      display: inline-block;
      margin-bottom: 20px;
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      border-radius: 6px !important;
      border: 3px solid #93C47D;
      background-color: white;
      color: #93C47D;
      font-weight: 600;
      cursor: pointer;
      padding: 7px 12px;
    margin-top: 18px;
  }
  .runbutton:hover,.runbutton:focus{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .table-bordered, .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: 1px solid #e7ecf1;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 8px !important;
 font-size: 14px;
 
  
}
  .fieldtext {
    width: 100%;
    height: 34px;
    padding: 6px 2px 7px 3px;
    outline: none;
    background-color: #fff;
    border: 1px solid #c2cad8;
    color: #555;
    
    border-radius: 6px !important;
  }
  .reporttext {
    display: flex;
    flex-direction: row;
  
  }
  .reportTable{
    background-color: #fff;
    border-radius: 10px !important;
    margin-bottom: 23px;
    padding: 15px;
  }
  .user-reporting-search-div {
    background-color: #fff;
    border-radius: 10px !important;
    /* margin-bottom: 23px; */
    padding: 15px;
}

  .row {
    margin-left: 24px;
    margin-right: 24px;

}
.table-responsive {
  overflow-x: auto;
  min-height: .01%;
}
 .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
  border: 1px solid #e7ecf1;
}
.report-table-28-days-div {
  margin-top: 20px;
}
.full-width {
  width: 100%;
}
.reports-table-28-days input, .months-table-report input {
    text-align: center;
    background: #fff;
    border: 1px solid #c2cad8;
    border-radius: 4px!important;
   
}
.tableDiv{
  padding: 8px;
}
.btn-Report {
  border-radius: 6px !important;
  border: 2px solid #93c47d !important;
  color: #839b78 !important;
  font-weight: bold !important;
  margin-top: 87px !important;
  background-color: white;
  padding: 7px 40px;
  cursor:pointer;
}
.reports-summary-table-div {
  background-color: #fff;
  border-radius: 10px !important;
  
}
.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}
  @media only screen and (max-width: 949px) {
    .reportNav {
        
        margin-top: 20%;
    }
    .reportingtextview {
       
        flex-direction: column;
        
    }
    .reportCard {
       
        padding: 9px;
        margin-left: 0px;
        
        margin-right: 0px;
       
    }
    .reporttext {
       
        flex-direction: column;
        
      }
      .labelfield {
       
        margin-bottom: 9px;
    }
    .labelfield label{
        padding-bottom: 2px;
    }
  }
  

.dev-detail-container{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}
.dev-detail-container span.title{
    font-size: 14px;
    color: black;
    font-weight: bold;
}
.dev-detail-container span.value{
    font-size: 14px;
    color: black;
    padding-left: 3px;
}
.devdtbtn1{
    background-color: white;
    border-radius: 10px;
    border:  solid #6C99E1;
    color: #6C99E1;
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
}

.devdtbtn2{
    background-color: white;
    border-radius: 10px;
    border:  solid #ff0000;
    color: #ff0000;
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
}

.devdtbtn2Disable{
    padding: 15px;
    border-radius: 10px;
    background-color: #e6e6e6;
    border: 3px solid #adadad;
    pointer-events: none;
    margin-bottom: 10px;
    width: 100%;
    color: #333;
}

.devdtbtn2:hover,.devdtbtn2:active,.devdtbtn1:hover,.devdtbtn1:active{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.in-edit{
    background-color: #e6e6e6;
    border: 1px solid #adadad;
    color: black;
}
.not-edit{
    border: 2px solid #93C47D;
    color: #93C47D;
    background-color: white;
}

.ysnobtn{
    background-color: white;
    color: #93C47D;
    border: 2px solid #93C47D;
    width:150px;
    height: 40px;
}
.newconfiginput{
    height: 30px;
    margin-top: 10px;
    background-color: #eef1f5;
    border: 1px solid #c2cad8;
}
.newconfiginputs{
    width:100%;
    height: 30px;
    background-color: #f4f4f4;
    border: 1px solid #c2cad8;
    border-radius: 4px;
    outline:none;
    cursor:not-allowed;
}
.newconfig{
    height: 30px;
    margin-top: 10px;
    outline:none;
    border: 1px solid #c2cad8;
}
.newconfigerr{
    height: 30px;
    margin-top: 10px;
    outline:none;
    border: 1px solid #c2cad8;
    background-color: #edd;
    border: 1px solid #c00;
}
.newconfiginputcheck{
    height: 30px;
    margin-top: 10px;
    margin-left: 20px;
}

.newconfiginputRow{
    height: 30px;
    margin-top: 10px;
    width: 300px;
    background-color: #eef1f5;
    border: 1px solid #c2cad8;
}

/* Wrap Edit Design */

.InputWrap {
    color: #6c6969;
    background-color: #e6e6e6;
    height: 30px;
    width: 100%;
    outline: none;
    border: 1px solid #adadad;
}
.InputWrapEdit {
    color: #6c6969;
    background-color: #fff;
    border: 1px solid #c2cad8;
    height: 30px;
    width: 100%;
    outline: none;
}
.TextAreaWrap {
    color: #6c6969;
    background-color: #e6e6e6;
    border-color: #adadad;
    height: 55px;
    width: 100%;
    outline: none;
    border: 1px solid #adadad;
    resize:none;
}
.TextAreaWrapEdit {
    color: #6c6969;
    background-color: #fff;
    border: 1px solid #c2cad8;
    height: 55px;
    width: 100%;
    outline: none;
    resize:none;
}
.SelectWrap {
    color: #6c6969;
    background-color: #e6e6e6;
    border-color: #adadad;
    height: 34px;
    width: 100%;
    outline: none;
    border: 1px solid #adadad;
}
.SelectWrapEdit {
    color: #6c6969;
    background-color: #fff;
    border: 1px solid #c2cad8;
    height: 34px;
    width: 100%;
    outline: none;
}
.Rowcells{
    font-size: 14px;
}
.rowNo{
    width: 115% !important;
    display: inline-block;
    font-size: 14px
}
.downstyle{
    color:#23527c;
    font-size: 14px;
}
.anchDownload:hover{
    cursor: pointer;
    text-decoration: underline;  
}
.Rowcell{
 width:25% !important;
 border-bottom: 1px solid #a8a6a6 !important;
 color:#221f1fe0;
 font-weight: 500;
 font-size: 14px;
}
.alert-success {
    background-color: #dff0d8;
    border-color: #abe7ed;
    color: #27a4b0;
    padding: 15px;
}
.RowTr{
    width:25% !important;
    border-bottom: 2px solid #e7ecf1 !important;
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2% !important;
    padding:10px !important;
   }
.RowTrs{
    width:25% !important;
    border-bottom: 1px solid #e7ecf1 !important;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 2% !important;
    padding:10px !important;
   }
.margTop{
    margin-top: 5%;
    margin-bottom: 3%;
}
.notifyTable{
border-collapse: collapse;
border-spacing: 0;
}
.RowTabs{
  border-bottom: 1px solid #a8a6a6 !important;
}
.addCards{
    width: 46%;
    height: 130px;
    background-color: #fff;
    border-radius: 10px !important;
    margin-right: 32px;
    display: inline-block;
    margin-bottom: 30px;
    border-color:#ccddff;
    background-color: #ccddff;
    font-size: 21px;
    color: #3377ff;
}

.marginPub{
    margin-left: 10px !important;
}

.marginPubs{
    margin-left: -58px !important;
}
.selfModalText{
    text-align: left;
    font-size: 13px;
    color: #939598;
    margin: 20px 0;
}

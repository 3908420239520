.licenseHead {
	font-size: 20px;
	color: #29b4b6;
	margin: 17px 29px;
	display: block;
}
.rowhead {
	display: flex;
	flex-direction: row;
	margin-left: 3%;
	margin-right: 4%;
	margin-bottom: 2%;
	justify-content: space-between;
}
.MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow: hidden !important;
    
}
.loaderpop{
	display: flex;
	justify-content: top;
	align-items: top;
	top: 0px;
	right: 0px;
	margin: auto;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1000;
   
	padding-top: 10%;
   border-radius: 5px;
   flex-direction: column;
	width: 70px; 
             
}
.loaderdata{
	display: flex;
	justify-content: top;
	align-items: top;
	top: 0px;
	right: 0px;
	margin: auto;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1000;
   
	padding-top: 13%;
   border-radius: 5px;
   flex-direction: column;
	width: 70px; 
             
}
.padding{
	padding-top: 15% !important;
}
.subrowhead {
	display: flex;
	flex-direction: row;
	margin-left: 3%;
	margin-right: 4%;
	margin-bottom: 2%;
}
.MuiTable-Cell-root {
	border: 1px solid #e7ecf1 !important;
}
.deployIProw {
	display: flex;
	flex-direction: row;

	margin-right: 4%;
	margin-bottom: 2%;
	justify-content: space-between;
}
.ServiceName {
	font-size: 15px;
	color: #221f1f;
	font-weight: 300;
	margin-bottom: 6px;
}
.bottomSpace {
	margin-bottom: 15px;
}
.divfieldSpace {
	height: 30px;
}
.paddingBottom {
	padding-bottom: 15px;
}
.nodetails {
	display: flex !important;
}
.imgStyle {
	height: 70px;
	width: 70px;
	object-fit: contain;
}
.MuiTableCell-root {
	display: table-cell;
	padding: 8px !important;
	font-size: 13px !important;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.43;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	letter-spacing: 0.01071em;
	vertical-align: inherit;
}
.editBtn {
	color: #333;
	height: 28px;
	background-color: #e6e6e6;
	border-color: #adadad;
	margin-top: 18px;
	padding: 5px 12px;
	font-weight: 700;
	border-radius: 6px;
}
.disabledHNIbutton{
    color: #666666a8;
    background-color: #ccccccc2;
    border-radius: 6px !important;
    font-size: 14px;
    height: 31px;
    font-weight: 400;
    padding: 2px 14px !important;
    cursor: pointer;
    margin-right: 9px;
    margin-bottom: 10px;
   

}
.selectMargin{
	margin-bottom:2px !important ;
}
.disable{
	color: #666666a8 !important;
    background-color: #f4f4f4 !important;
	cursor: not-allowed;
}
.appdialogtitle {
	color: #76a4af;
	font-size: 18px;
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
  margin: 22px 0px 12px 21px;
}
.appsubHead {
	color: #4986e8;
	margin: 22px 0px 12px 21px;
	font-size: 14px;
}
.spacefield {
	margin-top: 19px;
}
.gridsp {
	margin-left: 3%;
	margin-right: 3%;
}
.bottomRow {
	border: 1px solid #e7ecf1;
	padding: 8px;
	color: #333333;
	font-size: 14px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.editSubBtn {
	color: #333;
	height: 28px;
	background-color: #e6e6e6;
	border-color: #adadad;

	padding: 5px 12px;
	font-weight: 700;
	border-radius: 6px;
}
.errClassMsg {
	font-size: 13px;
	padding: 13px;
	color: #c00;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 1.42857;
}
.MuiDialog-paperWidthSm {
  max-width: 650px !important;
 
}

.MuiDialog-paperWidthLg {
	max-width: 950px !important;
   
  }
.connectModalTitle{
	font-weight: 600;
    color: #939598;
    font-size: 15px;
	margin-bottom: 10px;
    margin-top: 10px;
	font-family: "Open Sans", sans-serif;
}
.connectTitle{
    font-weight: 200;
    color: #6c6969;
}
.connectModalInput{
	background-color: #f4f4f4;
    border-radius: 3px!important;
	color: #333;
    border-color: #adadad;
    outline: none !important;
    box-shadow: none !important;
    cursor: not-allowed;
    height:38px;
	width: 100%;
}
.field {
	height: 30px;
	width: 90%;
	outline: none;
	padding-left: 8px;
	background-color: #f4f4f4;
	border: 1px solid #ccc;
	border-radius: 3px !important;
	margin-bottom: 10px;
	font-size: 14px;
}
.editedfield {
  height: 30px;
  width: 90%;
  outline: none;
  padding-left: 8px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px !important;
  margin-bottom: 10px;
  font-size: 14px;
}
.rowflex4 {
	display: flex;
	flex-direction: column;
}
.userfieldinput {
  height: 30px;
  width: 100%;
  color: #555;
  padding-left: 11px;
  outline: none;
 
  background-color: #f4f4f4;
  border-radius: 3px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.divBtn {
	text-align: right;
}
.datePicker {
  width: 100% !important;
}
.btnReview {
	border-radius: 6px !important;
	border: 1px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: 600 !important;
	padding: 4px 14px !important;
	cursor: pointer;
	margin-right: 2px;
	margin-bottom: 10px;
	background-color: #fff;
}
.react-datepicker__header {
  text-align: center !important;
  background-color: #f0f0f0 !important;
  border-bottom: 1px solid #aeaeae !important;
  border-top-left-radius: 0.3rem !important;
  /* padding: -1px 0 !important; */
  position: relative !important;
}
.react-datepicker__month {
  margin: -0.6rem !important;
  text-align: center !important;
 
}
.btnReview:hover {
	color: #333 !important;
	background-color: #f4f4f4 !important;
	border-color: #6e6a6a54 !important;
}
.MuiDialog-paperScrollBody {
	display: inline-block;
	text-align: left;
	vertical-align: top !important;
}

.MuiDialog-paper {
	margin: 19px !important;
	position: relative;
	overflow-y: auto;
}
.btnApproved {
	border-radius: 6px !important;
	border: 1px solid #1e90ff;
	color: #1e90ff;
	font-weight: 600;
	font-size: 12px;
	padding: 4px 14px !important;
	cursor: pointer;
	margin-right: 2px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #fff;
	border-radius: 6px !important;
	border: 1px solid #1e90ff;
	color: #1e90ff;
	font-weight: 600;
	font-size: 12px;
	padding: 4px 14px !important;
	cursor: pointer;
	margin-right: 2px;
	margin-bottom: 10px;
	background-color: #fff;
}
.btnApproved:hover {
	color: #333;
	background-color: #f4f4f4;
	border-color: #6e6a6a54;
}
.gridSpace {
	margin-left: 2% !important;
	margin-right: 2% !important;
}
.rowflex {
	display: flex;
	flex-direction: row;
	margin-left: 3%;
}
.rowflex2 {
	margin-left: 2%;
}
.fieldEdited {
	height: 30px;
	width: 90%;
	outline: none;
	padding-left: 8px;
	background-color: #ffffff;
	border: 1px solid #ccc;
	border-radius: 3px !important;
	margin-bottom: 3px;
	font-size: 12px;
}
.fieldEditederr {
	height: 30px;
	width: 90%;
	outline: none;
	padding-left: 8px;
	background-color: #edd;
	border: 1px solid #c00;
	border-radius: 3px !important;
	margin-bottom: 3px;
	font-size: 12px;
}

.fieldhead {
	font-weight: 700;
	font-size: 14px;
	color: #221f1f;
	margin: 17px 29px;
}
.typePoduct {
	display: flex;
	flex-direction: row;
}
.applnslisthr {
	border-top: 1px solid #9b9b9b;
	margin-left: 3%;
	margin-right: 4%;
}

.checkBoxtext {
	font-size: 14px;
	color: #221f1f;
	font-weight: 300;
	margin-bottom: 10px;
}
.addIPButton {
	border-radius: 6px !important;
	border: 2px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: bold !important;
	padding: 7px 14px !important;
	cursor: pointer;
	background-color: white;
	margin-right: 4%;
	margin-bottom: 10px;
}
.invitesdiv {
	text-align: right;
	margin-bottom: 5%;
}
.spaceDiv {
	height: 30px;
}
.topHead {
	font-weight: 700;
	font-size: 14px;
	margin-bottom: 6px;
}
.NewIpdialoginput {
	height: 30px;
	width: 22%;
	color: #555;
	outline: none;
	margin-left: 15%;
	margin-top: 3%;
	margin-bottom: 10px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.IpinputValue {
	height: 30px;
	width: 50%;
	color: #555;
	outline: none;

	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.ipsaveButton {
	border-radius: 6px !important;
	border: 2px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: bold !important;
	padding: 7px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
}
.ipdialogtitle {
	color: grey !important;
	font-size: 14px !important;
}
.ant-tabs {
 
    overflow: inherit !important;
	
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: transparent !important;
    border-color: transparent !important;
  
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    background: #fff  !important;
    border: 1px solid #ddd  !important;
	border-bottom-color: #fff !important;
}

.headXml{
	color: #939598;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: block;
	margin-top: 14px;
}
.apkStyle{
	width:30% !important;
}
.rdtPicker{
	margin-top: -175px !important;
	margin-left: 63%;
}

.rdt input[type="text" i] {
	width:95%;
	border:none;
	outline-color:transparent;
	background-color: transparent;
}
.rdt input[type="text" i]:active,.rdt input[type="text" i]:focus{
	width:95%;
	border:transparent !important;
	outline:transparent !important;
}
.wrapAccount{
	margin-top: 3%;
    margin-bottom: 5%;
	/* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%); */
}
.NavBoxRowFilterDisable {
    width: 76%;
    margin-bottom: 9%;
    padding: 6px 12px;
    background-color: #eef1f5;
    border: 1px solid #c2cad8;
    border-radius: 6px !important;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    display: block;
    outline: none !important;
    box-shadow: none !important;
    height: 23px;
	pointer-events: none;
}
.spaceBottom{
	margin-bottom: 0px;
}
.regCompany{
text-decoration: none;
font-weight: 600;
font-size: 14px;
color: #c3cedd;
}
.regCompany:hover{
	text-decoration: underline;
}
.utilityContent {
    display: flex;
    flex-direction: row;
    background-color: #e1e1e1;
    height: 100vh;
  }
  .utilityContents {
    display: flex;
    flex-direction: row;
    background-color: #e1e1e1;
    height: 125vh;
  }
  .utilityContentAdmin {
    display: flex;
    flex-direction: row;
    background-color: #e1e1e1;
  }
  .utilityView {
    overflow-x: scroll; 
    background-color: #fff;
    overflow: auto;
    overflow-y: auto;
    
    width: 100%;
   
    height: 100vh;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    padding: 9px;
  }
  .utilityexpView {
    background-color: #fff;
    margin-left: 95px;
    width: 100%;
    height: 316px;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
      0 3px 1px -2px rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
      0 3px 1px -2px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  .utilityhead {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    border-bottom: 1px solid #33333369;
    margin-top: 26px;
    margin-left: 24px;
  }
  .utilityheadIcon {
    font-size: 27px;
    color: #7D7C7C;
    height: 29px;
  }
  .utilToptext {
    font-size: 20px;
    color: #29b4b6;
    padding-left: 15px;
  }
  .tablestyle {
    border: 1px solid #e7ecf1;
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table thead tr th {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 8px !important;
    text-align: left !important;
    border: 1px solid #e7ecf1 !important;
  }
  .table>tbody>tr>th>td {
    border: 1px solid #e7ecf1 !important;
    padding: 8px !important;
    font-size: 14px !important;
  }
  .utilitytable {
    background-color: #fff;
    margin-top: 35px;
    overflow: hidden;
    margin-bottom: 25px;
  }
  .utilitytables {
    background-color: #fff;
    margin-top: 35px;
    margin-bottom: 25px;
    margin-left: 23px;
  }
  @media only screen and (max-width: 949px) {
    .utilitytable {
      overflow-y: hidden;
    }
    .utilitytables {
      overflow-y: hidden;
    }
    .utilityContent {
      margin-top: 30%;
    }
  }